import { Route, Routes } from "react-router-dom";
import Loader from "./components/layouts/loading/loading";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
const Login = React.lazy(() => import("./pages/auth/login"));
const Protected = React.lazy(() => import("./pages/auth/Protected"));
const Admin = React.lazy(() => import("./pages/admin/Admin"));
const Roles = React.lazy(() => import("./pages/admin/Roles"));
const Orders = React.lazy(() => import("./pages/orders/Orders"));
const AddRoles = React.lazy(() => import("./pages/admin/AddRoles"));
const AddOrder = React.lazy(() => import("./pages/orders/AddOrder"));
const AddAdmin = React.lazy(() => import("./pages/admin/AddAdmin"));
const AdminEdit = React.lazy(() => import("./pages/admin/AdminEdit"));
const ViewOrder = React.lazy(() => import("./pages/orders/ViewOrder"));
const Users = React.lazy(() => import("./pages/Users/Users"));
const Edit_Users = React.lazy(() => import("./pages/Users/Edit_Users"));
const AddUsers = React.lazy(() => import("./pages/Users/AddUsers"));
const AddRestaurantsWrap = React.lazy(() =>
  import("./pages/resturants/AddRestaurantsWrap")
);
const Resturants = React.lazy(() => import("./pages/resturants/Resturants"));
const RestaurantView = React.lazy(() =>
  import("./pages/resturants/RestaurantView")
);
const EditDrivers = React.lazy(() => import("./pages/Drivers/EditDrivers"));
const AddDrivers = React.lazy(() => import("./pages/Drivers/AddDrivers"));
const Drivers = React.lazy(() => import("./pages/Drivers/Drivers"));
const Class = React.lazy(() => import("./pages/ProductManagement/Class"));
const Category = React.lazy(() => import("./pages/ProductManagement/Category"));
const AddCategory = React.lazy(() =>
  import("./pages/ProductManagement/AddCategory")
);
const AddClass = React.lazy(() => import("./pages/ProductManagement/AddClass"));
const EditClass = React.lazy(() =>
  import("./pages/ProductManagement/EditClass")
);
const Country = React.lazy(() => import("./pages/Country/Country"));
const AddCountry = React.lazy(() => import("./pages/Country/AddCountry"));
const City = React.lazy(() => import("./pages/City/City"));
const AddCity = React.lazy(() => import("./pages/City/AddCity"));
const EditeZoneWrap = React.lazy(() => import("./pages/Zones/EditeZoneWrap"));
const Zones = React.lazy(() => import("./pages/Zones/Zones"));
const AddZoneWrap = React.lazy(() => import("./pages/Zones/AddZoneWrap"));
const EditCity = React.lazy(() => import("./pages/City/Edit_City"));
const ViewCity = React.lazy(() => import("./pages/City/ViewCity"));
const Transactions = React.lazy(() =>
  import("./pages/Transactions/Transactions")
);
const TransactionsView = React.lazy(() =>
  import("./pages/Transactions/TransactionsView")
);

const Plans = React.lazy(() => import("./pages/Plans/Plans"));
const PlansView = React.lazy(() => import("./pages/Plans/PlansView"));
const PlansEdit = React.lazy(() => import("./pages/Plans/PlansEdit"));
const PlansAdd = React.lazy(() => import("./pages/Plans/PlansAdd"));
const RestaurantEditWrap = React.lazy(() =>
  import("./pages/resturants/RestaurantEditWrap")
);
const EditCategory = React.lazy(() =>
  import("./pages/ProductManagement/EditCategory")
);
const DriversView = React.lazy(() => import("./pages/Drivers/DriversView"));
const Edit_Country = React.lazy(() => import("./pages/Country/Edit_Country"));
const Slider = React.lazy(() => import("./pages/Slider/Slider"));
const Edit_Slider = React.lazy(() => import("./pages/Slider/Edit_Slider"));
const AddSlider = React.lazy(() => import("./pages/Slider/AddSlider"));
const FinancialManagementOrders = React.lazy(() =>
  import("./pages/FinancialManagement/FinancialManagementOrders")
);
const ResturantPaymentOrders = React.lazy(() =>
  import("./pages/FinancialManagement/ResturantPaymentOrders")
);
const DriversPaymentOrders = React.lazy(() =>
  import("./pages/FinancialManagement/DriversPaymentOrders")
);
const ViewFinancialManagementOrders = React.lazy(() =>
  import("./pages/FinancialManagement/ViewFinancialManagementOrders")
);
const PaymentsOrdersBills = React.lazy(() =>
  import("./pages/FinancialManagement/PaymentsOrdersBills")
);

function App() {
  const { isAuthLoading } = useSelector((state) => state.AuthSlice);
  const { isAdminLoading } = useSelector((state) => state.AdminSlice);
  const { isProductLoading } = useSelector((state) => state.ProductSlice);
  const { isDriversLoading } = useSelector((state) => state.DriversSlice);
  const { isRestaurantLoading } = useSelector(
    (state) => state.RestaurantsSlice
  );
  return (
    <>
      {isAuthLoading && <Loader />}
      {isAdminLoading && <Loader />}
      {isProductLoading && <Loader />}
      {isDriversLoading && <Loader />}
      {isRestaurantLoading && <Loader />}
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route element={<Protected />}>
            {/* <Route path="*" element={<Home />} /> */}
            <Route path="*" element={<Resturants />} />
            <Route path="/" element={<Resturants />} />
            <Route path="/Admin" element={<Admin />} />
            <Route path="/Users" element={<Users />} />
            <Route path="/Edit_Users/:id" element={<Edit_Users />} />
            <Route path="/AddUsers" element={<AddUsers />} />

            <Route path="/Roles" element={<Roles />} />
            <Route path="/AddRoles" element={<AddRoles />} />
            <Route path="/Orders" element={<Orders />} />
            <Route path="/AddOrder" element={<AddOrder />} />
            <Route path="/AddAdmin" element={<AddAdmin />} />
            <Route path="/AdminEdit/:id" element={<AdminEdit />} />
            <Route path="/ViewOrder/:id" element={<ViewOrder />} />
            <Route path="/Resturants" element={<Resturants />} />
            <Route path="/AddRestaurants" element={<AddRestaurantsWrap />} />
            <Route path="/RestaurantView/:id" element={<RestaurantView />} />
            {/* <Route path="/Clients" element={<Clients />} />
            <Route path="/ClientsView/:id" element={<ClientsView />} /> */}
            <Route path="/EditCategory/:id" element={<EditCategory />} />
            <Route path="/Drivers" element={<Drivers />} />
            <Route path="/AddDrivers" element={<AddDrivers />} />
            <Route path="/EditDrivers/:id" element={<EditDrivers />} />
            <Route path="/Category" element={<Category />} />
            <Route path="/Class" element={<Class />} />
            <Route path="/AddCategory" element={<AddCategory />} />
            <Route path="/AddClass" element={<AddClass />} />
            <Route path="/EditClass/:id" element={<EditClass />} />
            <Route path="/Country" element={<Country />} />
            <Route path="/AddCountry" element={<AddCountry />} />
            <Route path="/City" element={<City />} />
            <Route path="/AddCity" element={<AddCity />} />
            <Route path="/Zones" element={<Zones />} />
            <Route path="/Addzones" element={<AddZoneWrap />} />
            <Route path="/edite-zones/:id" element={<EditeZoneWrap />} />
            {/* EditeZone */}
            <Route path="/ViewCity/:id" element={<ViewCity />} />
            <Route path="/Edit_City/:id" element={<EditCity />} />
            <Route path="/Edit_Country/:id" element={<Edit_Country />} />

            <Route
              path="/TransactionsView/:id"
              element={<TransactionsView />}
            />
            <Route path="/Transactions" element={<Transactions />} />
            <Route path="/Plans" element={<Plans />} />
            <Route path="/PlansView/:id" element={<PlansView />} />
            <Route path="/PlansEdit/:id" element={<PlansEdit />} />
            <Route
              path="/RestaurantEdit/:id"
              element={<RestaurantEditWrap />}
            />
            <Route path="/DriversView/:id" element={<DriversView />} />

            <Route path="/Slider" element={<Slider />} />
            <Route path="/AddSlider" element={<AddSlider />} />
            <Route path="/Edit_Slider/:id" element={<Edit_Slider />} />

            <Route path="/PlansAdd" element={<PlansAdd />} />
            <Route
              path="/FinancialManagementOrders"
              element={<FinancialManagementOrders />}
            />
            <Route
              path="/ResturantPaymentOrders"
              element={<ResturantPaymentOrders />}
            />
            <Route
              path="/DriversPaymentOrders"
              element={<DriversPaymentOrders />}
            />
            <Route
              path="/ViewFinancialManagementOrders/:id"
              element={<ViewFinancialManagementOrders />}
            />
            <Route
              path="/PaymentsOrdersBills"
              element={<PaymentsOrdersBills />}
            />
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
