import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getRestaurants = createAsyncThunk(
  "Restaurant/getRestaurants",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/restaurants`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: data && data,
        })
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getRestaurantsId = createAsyncThunk(
  "Restaurant/getRestaurantsId",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .get(
          `${process.env.REACT_APP_BACKEND_API}api/admin/restaurants/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addRestaurants = createAsyncThunk(
  "Restaurant/addRestaurants",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/restaurants`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateRestaurants = createAsyncThunk(
  "Restaurant/updateRestaurants",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/restaurants/${data.id}/update`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteRestaurants = createAsyncThunk(
  "Restaurant/deleteRestaurants",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .delete(
          `${process.env.REACT_APP_BACKEND_API}api/admin/restaurants/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);
      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const RestaurantsSlice = createSlice({
  name: "Restaurant",
  initialState: {
    isRestaurantLoading: false,
    error: null,
    RestaurantArr: null,
    RestaurantIDArr: null,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getRestaurants.pending, (state) => {
        state.isRestaurantLoading = true;
      })
      .addCase(getRestaurants.fulfilled, (state, action) => {
        state.isRestaurantLoading = false;
        state.RestaurantArr = action.payload.data;
      })
      .addCase(getRestaurants.rejected, (state, action) => {
        state.isRestaurantLoading = false;
      })
      // getRestaurantsId
      .addCase(getRestaurantsId.pending, (state) => {
        state.isRestaurantLoading = true;
      })
      .addCase(getRestaurantsId.fulfilled, (state, action) => {
        state.isRestaurantLoading = false;
        state.RestaurantIDArr = action.payload.data;
      })
      .addCase(getRestaurantsId.rejected, (state, action) => {
        state.isRestaurantLoading = false;
      })
      // addRestaurants
      .addCase(addRestaurants.pending, (state) => {
        state.isRestaurantLoading = true;
      })
      .addCase(addRestaurants.fulfilled, (state, action) => {
        state.isRestaurantLoading = false;
      })
      .addCase(addRestaurants.rejected, (state, action) => {
        state.isRestaurantLoading = false;
      })
      // updateRestaurants
      .addCase(updateRestaurants.pending, (state) => {
        state.isRestaurantLoading = true;
      })
      .addCase(updateRestaurants.fulfilled, (state, action) => {
        state.isRestaurantLoading = false;
      })
      .addCase(updateRestaurants.rejected, (state, action) => {
        state.isRestaurantLoading = false;
      })
      // deleteRestaurants
      .addCase(deleteRestaurants.pending, (state) => {
        state.isRestaurantLoading = true;
      })
      .addCase(deleteRestaurants.fulfilled, (state, action) => {
        state.isRestaurantLoading = false;
        state.RestaurantArr = state.RestaurantArr.filter(
          (ele) => ele.id !== action.meta.arg
        );
      })
      .addCase(deleteRestaurants.rejected, (state, action) => {
        state.isRestaurantLoading = false;
      });
  },
});

export default RestaurantsSlice.reducer;
