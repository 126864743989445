import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getClass = createAsyncThunk(
  "Product/getClass",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}api/admin/class`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: data && data,
        }
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getClassId = createAsyncThunk(
  "Product/getClassId",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/class/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// Adjusting addClass to handle form data and use form parameters
export const addClass = createAsyncThunk(
  "Product/addClass",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(`${process.env.REACT_APP_BACKEND_API}api/admin/class`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const EditClasss = createAsyncThunk(
  "Product/EditClasss",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/class/${data.id}/update`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const delClass = createAsyncThunk(
  "Product/delClass",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API}api/admin/class/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// =========================
export const getCategories = createAsyncThunk(
  "Product/getCategories",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}api/admin/categories`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: data && data,
        }
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const delCategories = createAsyncThunk(
  "Product/delCategories",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API}api/admin/categories/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addCategories = createAsyncThunk(
  "Product/addCategories",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/categories`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const EditCategory = createAsyncThunk(
  "Product/EditCategory",
  async ({ data, id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/categories/${id}/update`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCategoryId = createAsyncThunk(
  "Product/getCategoryId",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/categories/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// ============================
export const getCountries = createAsyncThunk(
  "Product/getCountries",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}api/admin/countries`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: data && data,
        }
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addCountry = createAsyncThunk(
  "Product/addCountry",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(`${process.env.REACT_APP_BACKEND_API}api/admin/countries`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const EditCountry = createAsyncThunk(
  "Product/EditCountry",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/countries/${data.id}/update`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const delCountry = createAsyncThunk(
  "Product/delCountry",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API}api/admin/بس عاوزك تفهم/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCountryId = createAsyncThunk(
  "Product/getCountryId",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/countries/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// ============================

// ============================
export const getCity = createAsyncThunk(
  "Product/getCity",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}api/admin/cities`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: data && data,
        }
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addCity = createAsyncThunk(
  "Product/addCity",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(`${process.env.REACT_APP_BACKEND_API}api/admin/cities`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const EditCity = createAsyncThunk(
  "Product/EditCity",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/cities/${data.id}/update`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const delCity = createAsyncThunk(
  "Product/delCity",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API}api/admin/cities/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCityId = createAsyncThunk(
  "Product/getCityId",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/cities/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// ============================

export const getZone = createAsyncThunk(
  "Product/getZone",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}api/admin/zones`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: data && data,
        }
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getZoneId = createAsyncThunk(
  "Product/getZoneId",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/zones/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addZone = createAsyncThunk(
  "Product/addZone",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(`${process.env.REACT_APP_BACKEND_API}api/admin/zones`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const EditZone = createAsyncThunk(
  "Product/EditZone",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .put(
          `${process.env.REACT_APP_BACKEND_API}api/admin/zones/${data.id}`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteZone = createAsyncThunk(
  "Product/deleteZone",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API}api/admin/zones/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// ==============================
export const getSlider = createAsyncThunk(
  "Product/getSlider",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}api/admin/sliders`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: data && data,
        }
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addSlider = createAsyncThunk(
  "Product/addSlider",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(`${process.env.REACT_APP_BACKEND_API}api/admin/sliders`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const EditSlider = createAsyncThunk(
  "Product/EditSlider",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/sliders/${data.id}/update`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const delSlider = createAsyncThunk(
  "Product/delSlider",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API}api/admin/sliders/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSliderId = createAsyncThunk(
  "Product/getSliderId",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/sliders/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// ==================================

// ============================
export const getUsers = createAsyncThunk(
  "Product/getUsers",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}api/admin/users`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: data && data,
        }
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addUsers = createAsyncThunk(
  "Product/addUsers",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(`${process.env.REACT_APP_BACKEND_API}api/admin/users`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const EditUsers = createAsyncThunk(
  "Product/EditUsers",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .put(
          `${process.env.REACT_APP_BACKEND_API}api/admin/users/${data.id}/update`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const delUsers = createAsyncThunk(
  "Product/delUsers",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API}api/admin/users/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUsersId = createAsyncThunk(
  "Product/getUsersId",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/users/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getOrders = createAsyncThunk(
  "Product/getOrders",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}api/admin/orders`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOrderID = createAsyncThunk(
  "Product/getOrderID",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}api/admin/orders/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateOrders = createAsyncThunk(
  "Product/updateOrders",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}api/admin/orders/${data.id}/update`,
        {
          status: data.status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


const ProductSlice = createSlice({
  name: "Product",
  initialState: {
    isProductLoading: false,
    error: null,
    ClassArray: null,
    ClassIDArray: null,
    CategoryIDArray: null,
    CountryIDArray: null,
    getCategoriesArray: null,
    getCountriesArray: null,
    getCityArray: null,
    CityIDArray: null,
    SliderIDArray: null,
    getSliderArray: null,
    UsersIDArray: null,
    getUsersArray: null,
    ZonesArray: null,
    ZonesIDArray: null,
    OrderArray: null,
    OrderIDArray: null,
  },
  extraReducers: (builder) => {
    builder
      // addCategories
      .addCase(addCategories.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(addCategories.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(addCategories.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // delCategories
      .addCase(delCategories.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(delCategories.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(delCategories.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // EditCategory
      .addCase(EditCategory.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(EditCategory.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(EditCategory.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // addUsers
      .addCase(addUsers.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(addUsers.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(addUsers.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // addCity
      .addCase(addCity.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(addCity.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(addCity.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // EditCity
      .addCase(EditCity.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(EditCity.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(EditCity.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // EditUsers
      .addCase(EditUsers.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(EditUsers.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(EditUsers.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // delUsers
      .addCase(delUsers.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(delUsers.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(delUsers.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(getUsersId.pending, (state) => {
        state.isProductLoading = false;
      })
      .addCase(getUsersId.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.UsersIDArray = action.payload.data;
      })
      .addCase(getUsersId.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(getUsers.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.getUsersArray = action.payload.data;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(getSliderId.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getSliderId.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.SliderIDArray = action.payload.data;
      })
      .addCase(getSliderId.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(getSlider.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getSlider.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.getSliderArray = action.payload.data;
      })
      .addCase(getSlider.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // addSlider
      .addCase(addSlider.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(addSlider.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(addSlider.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // EditSlider
      .addCase(EditSlider.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(EditSlider.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(EditSlider.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // delSlider
      .addCase(delSlider.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(delSlider.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.getSliderArray = state.getSliderArray.filter(
          (ele) => ele.id !== action.meta.arg
        );
      })
      .addCase(delSlider.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(getCountryId.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getCountryId.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.CountryIDArray = action.payload.data;
      })
      .addCase(getCountryId.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(getCityId.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getCityId.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.CityIDArray = action.payload.data;
      })
      .addCase(getCityId.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(getCity.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getCity.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.getCityArray = action.payload.data;
      })
      .addCase(getCity.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(getCountries.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.getCountriesArray = action.payload.data;
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(getCategoryId.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getCategoryId.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.CategoryIDArray = action.payload.data;
      })
      .addCase(getCategoryId.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // addCountry
      .addCase(addCountry.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(addCountry.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(addCountry.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // EditCountry
      .addCase(EditCountry.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(EditCountry.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(EditCountry.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(delCountry.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(delCountry.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.getCountriesArray = state.getCountriesArray.filter(
          (ele) => ele.id !== action.meta.arg
        );
      })
      .addCase(delCountry.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(getCategories.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.getCategoriesArray = action.payload.data;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(getClass.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getClass.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.ClassArray = action.payload.data;
      })
      .addCase(getClass.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(getClassId.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getClassId.fulfilled, (state, action) => {
        state.ClassIDArray = action.payload.data;
        state.isProductLoading = false;
      })
      .addCase(getClassId.rejected, (state, action) => {
        state.isProductLoading = false;
        state.error = action.payload;
      })
      .addCase(addClass.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(addClass.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(addClass.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(EditClasss.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(EditClasss.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(EditClasss.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // delClass
      .addCase(delClass.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(delClass.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.ClassArray = state.ClassArray.filter(
          (ele) => ele.id !== action.meta.arg
        );
      })
      .addCase(delClass.rejected, (state, action) => {
        state.isProductLoading = false;
      })

      // sad
      .addCase(getZone.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getZone.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.ZonesArray = action.payload.data;
      })
      .addCase(getZone.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(getZoneId.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getZoneId.fulfilled, (state, action) => {
        state.ZonesIDArray = action.payload.data;
        state.isProductLoading = false;
      })
      .addCase(getZoneId.rejected, (state, action) => {
        state.isProductLoading = false;
        state.error = action.payload;
      })
      .addCase(addZone.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(addZone.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(addZone.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(EditZone.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(EditZone.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(EditZone.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // delClass
      .addCase(deleteZone.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(deleteZone.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.ZonesArray = state.ZonesArray.filter(
          (ele) => ele.id !== action.meta.arg
        );
      })
      .addCase(deleteZone.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // getOrders
      .addCase(getOrders.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.OrderArray = action.payload.data;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // getOrderID
      .addCase(getOrderID.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getOrderID.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.OrderIDArray = action.payload.data;
      })
      .addCase(getOrderID.rejected, (state, action) => {
        state.isProductLoading = false;
      })
      // updateOrders
      .addCase(updateOrders.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(updateOrders.fulfilled, (state, action) => {
        state.isProductLoading = false;
      })
      .addCase(updateOrders.rejected, (state, action) => {
        state.isProductLoading = false;
      });
  },
});

export default ProductSlice.reducer;
