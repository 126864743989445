import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./AuthSlice";
import AdminSlice from "./AdminSlice";
import ProductSlice from "./ProductSlice";
import DriversSlice from "./DriversSlice";
import RestaurantsSlice from "./RestaurantsSlice";
import HomeSlice from "./HomeSlice";

export default configureStore({
  reducer: {
    AuthSlice,
    AdminSlice,
    ProductSlice,
    DriversSlice,
    RestaurantsSlice,
    HomeSlice,
  },
});
