import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getLogin = createAsyncThunk(
  "Auth/getLogin",
  async (resD, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/login`,
          {
            ...resD,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getLogout = createAsyncThunk(
  "Auth/getLogout",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/logout`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const AuthSlice = createSlice({
  name: "Auth",
  initialState: {
    isAuthLoading: false,
    error: null,
    LoginArr: null,
  },

  extraReducers: {
    [getLogin.pending]: (state, action) => {
      state.isAuthLoading = true;
    },
    [getLogin.fulfilled]: (state, action) => {
      state.LoginArr = action.payload.data;
      state.isAuthLoading = false;
    },
    [getLogin.rejected]: (state, action) => {
      state.isAuthLoading = false;
    },
    // getLogout
    [getLogout.pending]: (state, action) => {
      state.isAuthLoading = true;
    },
    [getLogout.fulfilled]: (state, action) => {
      state.isAuthLoading = false;
    },
    [getLogout.rejected]: (state, action) => {
      state.isAuthLoading = false;
    },
  },
});

export default AuthSlice.reducer;
