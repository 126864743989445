import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

// ============================
export const getDrivers = createAsyncThunk(
  "Drivers/getDrivers",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}api/admin/drivers`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: data && data,
        }
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addDrivers = createAsyncThunk(
  "Drivers/addDrivers",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(`${process.env.REACT_APP_BACKEND_API}api/admin/drivers`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const EditDrivers = createAsyncThunk(
  "Drivers/EditDrivers",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/drivers/${data.id}/update`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const delDrivers = createAsyncThunk(
  "Drivers/delDrivers",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API}api/admin/drivers/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDriversId = createAsyncThunk(
  "Drivers/getDriversId",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/drivers/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// ============================

// ============================
export const getPlan = createAsyncThunk(
  "Drivers/getPlan",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}api/admin/plans`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
          params: data && data,
        }
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addPlan = createAsyncThunk(
  "Drivers/addPlan",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(`${process.env.REACT_APP_BACKEND_API}api/admin/plans`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const EditPlan = createAsyncThunk(
  "Drivers/EditPlan",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const result = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/plans/${data.id}/update`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const delPlan = createAsyncThunk(
  "Drivers/delPlan",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API}api/admin/plans/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPlanId = createAsyncThunk(
  "Drivers/getPlanId",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/plans/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// ============================

const DriversSlice = createSlice({
  name: "Drivers",
  initialState: {
    isDriversLoading: false,
    error: null,
    UsersArr: null,
    getDriversArray: null,
    DriversIDArray: null,
    getPlanArray: null,
    PlanIDArray: null,
  },

  extraReducers: {
    [addDrivers.pending]: (state, action) => {
      state.isDriversLoading = true;
    },
    [addDrivers.fulfilled]: (state, action) => {
      state.isDriversLoading = false;
    },
    [addDrivers.rejected]: (state, action) => {
      state.isDriversLoading = false;
    },
    //
    //
    [delDrivers.pending]: (state, action) => {
      state.isDriversLoading = true;
    },
    [delDrivers.fulfilled]: (state, action) => {
      state.isDriversLoading = false;
    },
    [delDrivers.rejected]: (state, action) => {
      state.isDriversLoading = false;
    },
    //
    //
    [EditDrivers.pending]: (state, action) => {
      state.isDriversLoading = true;
    },
    [EditDrivers.fulfilled]: (state, action) => {
      state.isDriversLoading = false;
    },
    [EditDrivers.rejected]: (state, action) => {
      state.isDriversLoading = false;
    },
    //
    [getDriversId.pending]: (state, action) => {
      state.isDriversLoading = true;
    },
    [getDriversId.fulfilled]: (state, action) => {
      state.DriversIDArray = action.payload.data;
      state.isDriversLoading = false;
    },
    [getDriversId.rejected]: (state, action) => {
      state.isDriversLoading = false;
    },
    //
    [getDrivers.pending]: (state, action) => {
      state.isDriversLoading = true;
    },
    [getDrivers.fulfilled]: (state, action) => {
      state.getDriversArray = action.payload.data;
      state.isDriversLoading = false;
    },
    [getDrivers.rejected]: (state, action) => {
      state.isDriversLoading = false;
    },
    [getPlan.pending]: (state, action) => {
      state.isDriversLoading = true;
    },
    [getPlan.fulfilled]: (state, action) => {
      state.getPlanArray = action.payload.data;
      state.isDriversLoading = false;
    },
    [getPlan.rejected]: (state, action) => {
      state.isDriversLoading = false;
    },
    [addPlan.pending]: (state, action) => {
      state.isDriversLoading = true;
    },
    [addPlan.fulfilled]: (state, action) => {
      state.isDriversLoading = false;
    },
    [addPlan.rejected]: (state, action) => {
      state.isDriversLoading = false;
    },
    //
    //
    [delPlan.pending]: (state, action) => {
      state.isDriversLoading = true;
    },
    [delPlan.fulfilled]: (state, action) => {
      state.isDriversLoading = false;
    },
    [delPlan.rejected]: (state, action) => {
      state.isDriversLoading = false;
    },
    //
    //
    [EditPlan.pending]: (state, action) => {
      state.isDriversLoading = true;
    },
    [EditPlan.fulfilled]: (state, action) => {
      state.isDriversLoading = false;
    },
    [EditPlan.rejected]: (state, action) => {
      state.isDriversLoading = false;
    },
    //
    [getPlanId.pending]: (state, action) => {
      state.isDriversLoading = true;
    },
    [getPlanId.fulfilled]: (state, action) => {
      state.PlanIDArray = action.payload.data;
      state.isDriversLoading = false;
    },
    [getPlanId.rejected]: (state, action) => {
      state.isDriversLoading = false;
    },
  },
});

export default DriversSlice.reducer;
