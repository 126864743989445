import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getNoftfications = createAsyncThunk(
  "Home/getNoftfications",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BACKEND_API}api/admin/notifications`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
          },
        })
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// updateNotifications
export const updateNotifications = createAsyncThunk(
  "Home/updateNotifications",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}api/admin/notifications/${id}/mark-as-read`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("Willgo_Super_Token")}`,
            },
          }
        )
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const HomeSlice = createSlice({
  name: "Home",
  initialState: {
    isHomeLoading: false,
    error: null,
    NotificationsArr: null,
  },

  extraReducers: {
    [getNoftfications.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [getNoftfications.fulfilled]: (state, action) => {
      state.NotificationsArr = action.payload.data;
      state.isHomeLoading = false;
    },
    [getNoftfications.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },
    // updateNotifications
    [updateNotifications.pending]: (state, action) => {
      state.isHomeLoading = true;
    },
    [updateNotifications.fulfilled]: (state, action) => {
      state.isHomeLoading = false;
    },
    [updateNotifications.rejected]: (state, action) => {
      state.isHomeLoading = false;
    },
  },
});

export default HomeSlice.reducer;
